<template>
  <footer class="footer">
    <p>
      <a href="mailto:acastelo@impro-assessors.es"
        >acastelo@impro-assessors.es</a
      >
    </p>
  </footer>
</template>

<script>
export default {
  name: 'FooterBar',
}
</script>

<style lang="scss" scoped>
.footer {
  min-height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1440px) {
  .footer {
    min-height: 3rem;
  }
}
</style>
