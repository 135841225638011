<template>
  <section id="contact" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 m-4">
    <div class="lg:text-center md:pt-8 sm:pt-4">
      <h2
        class="text-base text-indigo-600 font-semibold tracking-wide uppercase"
      >
        {{ $t('lang.section-title.contact') }}
      </h2>
      <p class="mt-4 max-w-4xl text-xl text-gray-500 lg:mx-auto">
        {{ $t('lang.descriptions.about-desc') }}
      </p>
    </div>

    <div class="flex justify-center mt-10 bg-gray-50 p-8">
      <form class="w-full max-w-lg" action="mailto:rohit@k.com">
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-first-name"
            >
              {{ $t('lang.form.name') }}
            </label>
            <input
              id="grid-first-name"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              type="text"
              :placeholder="$t('lang.form.name-placeholder')"
            />
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-last-name"
            >
              {{ $t('lang.form.surname') }}
            </label>
            <input
              id="grid-last-name"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              :placeholder="$t('lang.form.surname-placeholder')"
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >
              {{ $t('lang.form.email') }}
            </label>
            <input
              id="email"
              :placeholder="$t('lang.form.email-placeholder')"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="email"
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >
              {{ $t('lang.form.phone') }}
            </label>
            <input
              id="email"
              :placeholder="$t('lang.form.phone-placeholder')"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="email"
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >
              {{ $t('lang.form.subject') }}
            </label>
            <textarea
              id="message"
              :placeholder="$t('lang.form.subject-placeholder')"
              class="no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
            ></textarea>
          </div>
        </div>
        <div class="md:flex md:items-center">
          <div>
            <a
              class="bg-indigo-500 text-white shadow font-bold py-2 px-8 rounded hover:bg-indigo-400"
              href="mailto:impro-assessors@gmail.com"
            >
              {{ $t('lang.form.send-btn') }}
            </a>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Contact',
}
</script>
