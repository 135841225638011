<template>
  <div id="app">
    <navbar />
    <cover-image />
    <about-us />
    <contact />
    <footer-bar />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import CoverImage from '@/components/CoverImage'
import AboutUs from '@/components/AboutUs'
import Contact from '@/components/Contact'
import FooterBar from '@/components/FooterBar'

export default {
  name: 'App',
  components: {
    Navbar,
    CoverImage,
    AboutUs,
    Contact,
    FooterBar,
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 12px;
}

body {
  margin: 0;
  padding: 0;
}
</style>
