<template>
  <section class="cover">
    <div class="left"></div>
    <div class="right">
      <h2 class="text-3xl sm:text-5xl my-2 section-title">
        {{ $t('lang.cover-image.cover-title') }}
      </h2>
      <h4 class="text-xl sm:text-2xl my-2 section-subtitle">
        {{ $t('lang.cover-image.cover-subtitle') }}
      </h4>
      <p class="text-sm sm:text-lg px-9 section-description">
        {{ $t('lang.cover-image.cover-description') }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CoverImage',
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat:wght@300&family=Oswald:wght@700&display=swap');

.cover {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}

.left,
.right {
  height: 100%;
  width: 50%;
}

.left {
  background-image: url('../assets/img/portada.jpg');
  background-size: cover;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-title {
  font-family: 'Oswald', sans-serif;
}

.section-subtitle {
  font-family: 'Montserrat', sans-serif;
}

.section-description {
  font-family: 'Lato', sans-serif;
}

@media (max-width: 1024px) {
  .left,
  .right {
    width: 100%;
    height: auto;
    min-height: 50vh;
  }
}
</style>
