<template>
  <nav class="navbar">
    <div class="logo-wrapper">
      <div>
        <img
          src="@/assets/img/logo1.png"
          alt="Logo Improassessors"
          class="img-logo"
        />
      </div>
      <span id="text-logo"><b>IMPRO</b>ASSESSORS</span>
    </div>
    <div id="menu">
      <ul class="nav">
        <li class="menu-item">
          <a class="link" href="#">{{ $t('lang.section-title.home') }}</a>
        </li>
        <li class="menu-item">
          <a class="link" href="#about">{{ $t('lang.section-title.about') }}</a>
        </li>
        <li class="menu-item">
          <a class="link" href="#contact">{{
            $t('lang.section-title.contact')
          }}</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat:wght@300&family=Oswald:wght@700&display=swap');
//NAVBAR STYLES COMPONENT
.navbar {
  display: flex;
  justify-content: space-between;
  padding: 14px 24px;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  height: 64px;
  box-shadow: 0px 1px 8px grey;
  background-color: white;
}

.img-logo {
  height: 28px;
}

.logo-wrapper {
  width: 240px;
  max-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 1.3em;
}

.nav {
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  font-size: 1.1em;
  font-family: 'Lato';
}

.menu-item {
  padding: 14px 16px;
}

.link {
  text-decoration: none;
  color: black;
}

@media (max-width: 768px) {
  .nav {
    display: none;
  }

  .navbar {
    justify-content: center;
  }
}
</style>
